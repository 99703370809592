<template>

  <b-row>

    <!-- Left -->
    <b-col
      cols="12"
      lg="9"
    >

      <b-alert
        :show="userData.role === 'admin' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        Please see the following list of requested changes.
        <ul
          class="d-flex flex-column flex-wrap mt-50"
          style="max-height: 300px;"
        >
          <li
            v-for="(value, index) in formUpdateChanges"
            :key="index"
          >
            {{ value }}
          </li>
        </ul>
        <hr>
        <div class="text-right">
          <b-button
            variant="danger"
            class="mr-1"
            @click="denyFormUpdate"
          >
            Deny
          </b-button>
          <b-button
            variant="secondary"
            @click="approveFormUpdate"
          >
            Approve
          </b-button>
        </div>
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        You still have pending profile update requests. Please wait for the Admin to review them before making any other changes.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 2"
        variant="primary"
        dismissible
        fade
        style="padding: 0.71rem 1rem; cursor: pointer; border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        The HR/Admin has approved the changes you made.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 3"
        variant="danger"
        dismissible
        fade
        style="border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        <div
          style="padding: 0.71rem 1rem; cursor: pointer;"
          @click="dismissFormUpdate"
        >
          The HR/Admin has denied the changes you made.
        </div>
      </b-alert>

      <b-overlay
        :show="userData.role === 'agent' && formUpdateStatus > 0"
        spinner-type="false"
        opacity="0.25"
        blur
        rounded
      >

        <!-- Card -->
        <b-card>

          <b-row class="hr-form">

            <b-col
              cols="12"
            >

              <b-card-title>
                Employment Skills List
              </b-card-title>

              <!-- Form -->
              <validation-observer ref="simpleRules">
                <b-form>

                  <b-form-input
                    v-model="form.id"
                    type="text"
                    class="hidden"
                  />

                  <b-form-input
                    v-model="form.cid"
                    type="text"
                    class="hidden"
                  />

                  <b-row>

                    <!-- Input Applicant Name -->
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Applicant Name"
                        label-for="applicant-name"
                      >
                        <label
                          class="sr-only"
                          for="applicant-name"
                        >
                          * Applicant Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Applicant Name"
                          vid="applicant-name"
                          rules="required"
                        >
                          <b-form-input
                            id="applicant-name"
                            v-model="applicantName"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Applicant Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <hr>

                  <p>Please check ALL that describes your Interests, Hobbies and Social Activities:</p>

                  <!-- Checkbox Interest/Hobbies -->
                  <b-row>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group>
                        <b-form-checkbox
                          ref="interestHobbies"
                          v-model="form.interestHobbies"
                          value="Playing Musical Instrument"
                          title="Playing Musical Instrument"
                          @change="input = !input"
                        >
                          Playing Musical Instrument
                        </b-form-checkbox>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group>
                        <b-form-input
                          ref="interestHobbiesInput"
                          v-model="form.interestHobbiesInput"
                          placeholder="Piano, Guitar, Violin, Drums, Saxophone, Flute, etc."
                          :disabled="input"
                          title="Playing Musical Instrument"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <b-col
                      cols="12"
                    >

                      <b-form-group>
                        <b-form-checkbox
                          ref="interestHobbies1"
                          v-model="form.interestHobbies1"
                          value="Reading"
                          inline
                          title="Reading"
                          @change="checkbox = !checkbox"
                        >
                          Reading
                        </b-form-checkbox>
                        <b-form-checkbox-group
                          ref="interestHobbiesCheckbox"
                          v-model="form.interestHobbiesCheckbox"
                          :options="['Books', 'Magazine', 'Newspaper']"
                          class="d-inline"
                          :disabled="checkbox"
                          title="Books, Magazine, Newspaper"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group>
                        <b-form-checkbox
                          ref="interestHobbies2"
                          v-model="form.interestHobbies2"
                          value="Cooking"
                          title="Cooking"
                        >
                          Cooking
                        </b-form-checkbox>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <b-col
                      cols="12"
                    >

                      <b-form-group>
                        <b-form-checkbox-group
                          ref="interestHobbies3"
                          v-model="form.interestHobbies3"
                          :options="['Shopping', 'Travelling']"
                          title="Shopping, Travelling"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <b-col
                      cols="12"
                    >

                      <b-form-group>
                        <b-form-checkbox-group
                          ref="interestHobbies4"
                          v-model="form.interestHobbies4"
                          :options="['Dancing', 'Singing']"
                          title="Dancing, Singing"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <b-col
                      cols="12"
                    >

                      <b-form-group>
                        <b-form-checkbox
                          ref="interestHobbies5"
                          v-model="form.interestHobbies5"
                          value="Crafts"
                          inline
                          title="Crafts"
                          @change="checkbox1 = !checkbox1"
                        >
                          Crafts
                        </b-form-checkbox>
                        <b-form-checkbox-group
                          ref="interestHobbiesCheckbox1"
                          v-model="form.interestHobbiesCheckbox1"
                          :options="['Crocheting', 'Knitting', 'Scrapbooking']"
                          class="d-inline"
                          :disabled="checkbox1"
                          title="Crocheting, Knitting, Scrapbooking"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <b-col
                      cols="12"
                    >

                      <b-form-group>
                        <b-form-checkbox-group
                          ref="interestHobbies6"
                          v-model="form.interestHobbies6"
                          :options="['Gardening', 'Flower Arranging']"
                          title="Gardening, Flower Arranging"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group>
                        <b-form-checkbox
                          ref="interestHobbies7"
                          v-model="form.interestHobbies7"
                          value="Watching TV"
                          title="Watching TV"
                        >
                          Watching TV
                        </b-form-checkbox>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <b-col
                      cols="12"
                    >

                      <b-form-group>
                        <b-form-checkbox
                          ref="interestHobbies8"
                          v-model="form.interestHobbies8"
                          value="Pet Lover"
                          inline
                          title="Pet Lover"
                          @change="checkbox2 = !checkbox2, input1 = !input1"
                        >
                          Pet Lover
                        </b-form-checkbox>
                        <b-form-checkbox-group
                          ref="interestHobbiesCheckbox2"
                          v-model="form.interestHobbiesCheckbox2"
                          :options="['Dog', 'Cat', 'Birds']"
                          class="d-inline"
                          :disabled="checkbox2"
                          title="Dog, Cat, Birds"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group>
                        <b-form-input
                          ref="interestHobbiesInput1"
                          v-model="form.interestHobbiesInput1"
                          placeholder="Fish, Reptiles, Rabbits, Hamsters, Guinea pigs, Ferrets, etc."
                          :disabled="input1"
                          title="Pet Lover"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group>
                        <b-form-checkbox
                          ref="interestHobbies9"
                          v-model="form.interestHobbies9"
                          value="Sports"
                          title="Sports"
                          @change="input2 = !input2"
                        >
                          Sports
                        </b-form-checkbox>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group>
                        <b-form-input
                          ref="interestHobbiesInput2"
                          v-model="form.interestHobbiesInput2"
                          placeholder="American football, Baseball/Softball, Basketball, Ice hockey, Soccer, etc."
                          :disabled="input2"
                          title="Sports"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <b-col
                      cols="12"
                    >

                      <b-form-group>
                        <b-form-checkbox
                          ref="interestHobbies10"
                          v-model="form.interestHobbies10"
                          value="Card Games"
                          inline
                          title="Card Games"
                          @change="checkbox3 = !checkbox3, input3 = !input3"
                        >
                          Card Games
                        </b-form-checkbox>
                        <b-form-checkbox-group
                          ref="interestHobbiesCheckbox3"
                          v-model="form.interestHobbiesCheckbox3"
                          :options="['Table Games']"
                          class="d-inline"
                          :disabled="checkbox3"
                          title="Table Games"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group>
                        <b-form-input
                          ref="interestHobbiesInput3"
                          v-model="form.interestHobbiesInput3"
                          placeholder="Poker, Blackjack, Craps, Roulette, Baccarat, etc."
                          :disabled="input3"
                          title="Card Games"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <b-col
                      cols="12"
                    >

                      <b-form-group>
                        <b-form-checkbox
                          ref="interestHobbies11"
                          v-model="form.interestHobbies11"
                          value="Social Media"
                          inline
                          title="Social Media"
                          @change="checkbox4 = !checkbox4, input4 = !input4"
                        >
                          Social Media
                        </b-form-checkbox>
                        <b-form-checkbox-group
                          ref="interestHobbiesCheckbox4"
                          v-model="form.interestHobbiesCheckbox4"
                          :options="['Facebook', 'Instagram']"
                          class="d-inline"
                          :disabled="checkbox4"
                          title="Facebook, Instagram"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group>
                        <b-form-input
                          ref="interestHobbiesInput4"
                          v-model="form.interestHobbiesInput4"
                          placeholder="YouTube, Twitter, TikTok, Pinterest, Snapchat, etc."
                          :disabled="input4"
                          title="Social Media"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <b-col
                      cols="12"
                    >

                      <b-form-group>
                        <b-form-checkbox-group
                          ref="interestHobbies12"
                          v-model="form.interestHobbies12"
                          :options="['Painting', 'Drawing', 'Sketching']"
                          title="Painting, Drawing, Sketching"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <b-col
                      cols="12"
                    >

                      <b-form-group>
                        <b-form-checkbox-group
                          ref="interestHobbies13"
                          v-model="form.interestHobbies13"
                          :options="['Outing', 'Picnic', 'Walk in the Park', 'Fishing']"
                          title="Outing, Picnic, Walk in the Park, Fishing"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <b-col
                      cols="12"
                    >

                      <b-form-group>
                        <b-form-checkbox-group
                          ref="interestHobbies14"
                          v-model="form.interestHobbies14"
                          :options="['Handyman', 'Tailoring', 'Dressmaking']"
                          title="Handyman, Tailoring, Dressmaking"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <hr>

                  <p>Please WRITE special things we need to know about you:</p>

                  <b-row>

                    <!-- Textarea Special Things -->
                    <b-col
                      cols="12"
                    >

                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Special Things"
                          vid="special-things"
                          rules="required"
                        >
                          <b-form-textarea
                            id="special-things"
                            ref="specialThings"
                            v-model="form.specialThings"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Type here..."
                            rows="5"
                            title="Special Things"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Button Submit -->
                  <b-button
                    variant="primary"
                    type="submit"
                    class="mr-1 d-none"
                    @click.prevent="validationForm"
                  >
                    Submit
                  </b-button>
                </b-form>
              </validation-observer>

            </b-col>
          </b-row>

        </b-card>

      </b-overlay>
    </b-col>

    <!-- Right -->
    <b-col
      cols="12"
      lg="3"
    >

      <!-- Action Buttons -->
      <b-card>

        <!-- Button Preview -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="gradient-main"
          class="mb-75 btn-print-preview"
          block
          @click="$store.dispatch('app-hr/preview')"
        >
          Print Preview
        </b-button>

        <!-- Button Save -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          block
          :disabled="(userData.role === 'agent' && formUpdateStatus > 0) ? true : isNotSaved"
          @click.prevent="validationForm"
        >
          Save
        </b-button>

        <!-- Button Cancel -->
        <b-button
          v-if="action !== 'preview'"
          variant="outline-dark"
          class="mb-75"
          :to="{ name: (userData.role === 'agent' ? 'caregiverLanding' : 'hr') }"
          block
        >
          Cancel
        </b-button>

        <div class="d-flex">
          <!-- Button Previous -->
          <b-button
            variant="outline-dark"
            class="flex-fill mr-25"
            :to="{ name: 'apps-hr-form2', params: { action: action }, query: { id: id } }"
          >
            Previous
          </b-button>

          <!-- Button Next -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            class="flex-fill ml-25"
            :to="{ name: 'apps-hr-form4', params: { action: action }, query: { id: id } }"
          >
            Next
          </b-button>
        </div>

        <!-- Spacer -->
        <hr class="mb-50">

        <label for="forms">Form Selection</label>
        <v-select
          id="forms"
          v-model="forms"
          :reduce="(option) => option.name"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="formsOptions"
          :clearable="false"
          :disabled="!id"
          class="hr-forms-select"
          @input="$router.push({ name: forms, params: { action: action }, query: { id: id } })"
        >
          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>
        </v-select>
      </b-card>

    </b-col>
  </b-row>

</template>

<script>
import SockJS from 'sockjs-client'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BOverlay, BAlert, BCard, BCardTitle, BRow, BCol, BForm, BFormGroup, BFormInput, BFormCheckbox, BFormCheckboxGroup, BFormTextarea, BButton, VBToggle,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import Swal from 'sweetalert2'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

import { getUserData } from '@/auth/utils'

import hrStoreModule from '../hrStoreModule'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BOverlay,
    BAlert,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormTextarea,
    BButton,

    vSelect,
    // eslint-disable-next-line
    Swal,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  setup() {
    const HR_APP_STORE_MODULE_NAME = 'app-hr'

    // Register module
    if (!store.hasModule(HR_APP_STORE_MODULE_NAME)) store.registerModule(HR_APP_STORE_MODULE_NAME, hrStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HR_APP_STORE_MODULE_NAME)) store.unregisterModule(HR_APP_STORE_MODULE_NAME)
    })

    const form = ref({})

    const applicantName = ref('')

    const input = ref(true)
    const input1 = ref(true)
    const input2 = ref(true)
    const input3 = ref(true)
    const input4 = ref(true)
    const checkbox = ref(true)
    const checkbox1 = ref(true)
    const checkbox2 = ref(true)
    const checkbox3 = ref(true)
    const checkbox4 = ref(true)

    const formUpdate = ref({})
    const formUpdateStatus = ref(0)

    const formCopy = ref({})

    if (router.currentRoute.query.id) {
      store.dispatch('app-hr/fetchForm', { id: router.currentRoute.query.id })
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          // console.log(jsonObjectLiterals.data)

          form.value = {}

          form.value.cid = router.currentRoute.query.id

          // eslint-disable-next-line
          applicantName.value = jsonObjectLiterals.data.firstName + (jsonObjectLiterals.data.middleName ? ' ' + jsonObjectLiterals.data.middleName + ' ' : ' ') + jsonObjectLiterals.data.lastName

          if (router.currentRoute.params.action === 'edit' || (router.currentRoute.params.action === 'add' && router.currentRoute.query.id)) {
            store.dispatch('app-hr/fetchForm3', { cid: router.currentRoute.query.id })
              .then(response1 => {
                // eslint-disable-next-line
                const jsonObjectLiterals = response1.data.response.data

                // console.log(jsonObjectLiterals.data)

                if (jsonObjectLiterals.data.cid) {
                  form.value = jsonObjectLiterals.data
                }

                if (jsonObjectLiterals.data.interestHobbies) {
                  input.value = false
                }

                if (jsonObjectLiterals.data.interestHobbies8) {
                  input1.value = false
                }

                if (jsonObjectLiterals.data.interestHobbies9) {
                  input2.value = false
                }

                if (jsonObjectLiterals.data.interestHobbies10) {
                  input3.value = false
                }

                if (jsonObjectLiterals.data.interestHobbies11) {
                  input4.value = false
                }

                if (jsonObjectLiterals.data.interestHobbies1) {
                  checkbox.value = false
                }

                if (jsonObjectLiterals.data.interestHobbies5) {
                  checkbox1.value = false
                }

                if (jsonObjectLiterals.data.interestHobbies8) {
                  checkbox2.value = false
                }

                if (jsonObjectLiterals.data.interestHobbies10) {
                  checkbox3.value = false
                }

                if (jsonObjectLiterals.data.interestHobbies11) {
                  checkbox4.value = false
                }

                if (form.value.id) {
                  form.value.form = 'folder3_form3'

                  formCopy.value = JSON.parse(JSON.stringify(form.value))

                  store.dispatch('app-hr/fetchFormUpdate', { form: form.value.form, form_id: form.value.id })
                    .then(response2 => {
                      // eslint-disable-next-line
                      const jsonObjectLiterals = response2.data.response.data

                      // console.log(jsonObjectLiterals.data)

                      if (jsonObjectLiterals.data.status) {
                        formUpdate.value = jsonObjectLiterals.data

                        formUpdateStatus.value = formUpdate.value.status
                      }
                    })
                    .catch(error => {
                      console.error(error)
                    })
                }
              })
              .catch(error => {
                console.error(error)
              })
          }
        })
        .catch(error => {
          console.error(error)
        })
    }

    const userData = ref(getUserData())

    const formsOptions = [
      { label: 'Basic Information', name: 'apps-hr-form' },
      { label: 'Employment Checklist', name: 'apps-hr-form1' },
      { label: 'Employment Application', name: 'apps-hr-form2' },
      { label: 'Employment Skills List', name: 'apps-hr-form3' },
      { label: 'Work Experiences Checklist', name: 'apps-hr-form4' },
      { label: 'Employment Eligibility Verification', name: 'apps-hr-form5' },
      { label: 'Form W-4', name: 'apps-hr-form6' },
      { label: 'Non-Compete and Non-Solicitation Agreement', name: 'apps-hr-form7' },
      { label: 'Employment Verification Fax', name: 'apps-hr-form8' },
      { label: 'Ref Inquiry Form', name: 'apps-hr-form9' },
      { label: 'Request for Live Scan Service - Community Care Licensing', name: 'apps-hr-form10' },
      { label: 'New Hire Orientation Acknowledgment Form', name: 'apps-hr-form11' },
      { label: 'Employee Tuberculin Skin Test (TST) and Evaluation', name: 'apps-hr-form12' },
      { label: 'Home Care Organization Inspection Checklist', name: 'apps-hr-form13' },
      { label: 'Personnel Record (Admin)', name: 'apps-hr-form14a' },
      { label: 'Personnel Record', name: 'apps-hr-form14b' },
      { label: 'Applicant for Home Care Aide Registration', name: 'apps-hr-form14c' },
      { label: 'Criminal Record Statement', name: 'apps-hr-form15' },
      { label: 'Statement Acknowledging Requirement to Report Suspected Abuse of Dependent Adults and Elders', name: 'apps-hr-form16' },
      { label: 'Registered Home Care Aide Training Log A', name: 'apps-hr-form17a' },
      { label: 'Registered Home Care Aide Training Log B', name: 'apps-hr-form17b' },
      { label: 'Workplace Safety', name: 'apps-hr-form18' },
      { label: 'Current Knowledge', name: 'apps-hr-form19' },
      { label: 'Fact Sheet (Home Care Services - April 2017)', name: 'apps-hr-form20' },
      { label: 'Acknowledgement Form - Fact Sheet (Home Care Services)', name: 'apps-hr-form21' },
      { label: 'Training Completion Certificate', name: 'apps-hr-form22' },
    ]

    const sock = new SockJS('https://socket.motivit.com:443/echo')
    const sockId = 'coc-n01'

    return {
      form,

      applicantName,

      input,
      input1,
      input2,
      input3,
      input4,
      checkbox,
      checkbox1,
      checkbox2,
      checkbox3,
      checkbox4,

      formUpdate,
      formUpdateStatus,

      formCopy,

      userData,

      formsOptions,

      sock,
      sockId,
    }
  },
  data() {
    return {
      action: this.$route.params.action,
      id: this.$route.query.id,

      isNotSaved: false,

      forms: this.$route.name,

      formUpdateChanges: [],

      required,
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'formUpdate.data': function () {
      Object.entries(this.formUpdate.data).forEach(([key, val]) => {
        if (JSON.stringify(val) !== JSON.stringify(this.formCopy[key]) && (Number(val) !== Number(this.formCopy[key]) || String(val) !== String(this.formCopy[key]))) {
          this.formUpdateChanges.push(this.$refs[key].$attrs.title)
        }
      })
    },
  },
  created() {
    this.sock.onopen = () => {
      // console.log('Connection established.')
    }

    this.sock.onmessage = event => {
      const data = JSON.parse(event.data)
      if (data.id === this.sockId) {
        this.getSockMsg(data.msg)
      }
    }

    this.sock.onclose = () => {
      // console.warn('Connection closed.')
    }
  },
  methods: {
    getSockMsg(sockMsg) {
      if (sockMsg === true) {
        // Do something
      }
    },
    sendSockMsg(sockMsg) {
      this.sock.send(JSON.stringify({ id: this.sockId, msg: sockMsg }))
    },
    requestFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/requestFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 1

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    denyFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/denyFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          if (jsonObjectLiterals.data.id) {
            self.form = jsonObjectLiterals.data
          }

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    approveFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/approveFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    dismissFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/dismissFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    validationForm() {
      const self = this

      this.$refs.simpleRules.validate().then(success => {
        /* this.isNotSaved = true

        store
          .dispatch(this.action === 'add' ? 'app-hr/addForm3' : 'app-hr/updateForm3', this.form)
          .then(response => {
            // eslint-disable-next-line
            const jsonObjectLiterals = response.data.response.data

            this.form.id = jsonObjectLiterals.id

            // console.log(jsonObjectLiterals)

            Swal.fire({
              icon: 'success',
              title: 'Saved!',
              text: 'Saved successfully.',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch(error => {
            console.error(error)

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .finally(() => {
            self.isNotSaved = false
          }) */
        if (success) {
          let msg = 'Do you want to save the changes?'
          if (this.action === 'add') msg = 'Do you want to add these data?'
          Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: msg,
            confirmButtonText: 'Proceed',
            showCancelButton: true,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.isNotSaved = true

              store
                .dispatch(this.action === 'add' ? 'app-hr/addForm3' : 'app-hr/updateForm3', this.form)
                .then(response => {
                  // eslint-disable-next-line
                  const jsonObjectLiterals = response.data.response.data

                  this.form.id = jsonObjectLiterals.id

                  // console.log(jsonObjectLiterals)

                  if (this.userData.role === 'agent' && this.action === 'edit' && jsonObjectLiterals.count > 0) {
                    self.requestFormUpdate()
                  }

                  Swal.fire({
                    icon: 'success',
                    title: 'Saved!',
                    text: 'Saved successfully.',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .catch(error => {
                  console.error(error)

                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .finally(() => {
                  self.isNotSaved = false
                })
            }
          })

          // eslint-disable-next-line
          // alert('form submitted!')
        } else {
          Swal.fire({
            icon: 'error',
            // title: 'Are you sure?',
            // eslint-disable-next-line
            text: 'Please fill-up all of the required fields.',
            confirmButtonText: 'OK',
            showCancelButton: false,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.hr-forms-select {
  width: 100%;
  min-width: 182px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 135px;
  }
}

@media (max-width: 1199.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 112px;
    }
  }
}

@media (max-width: 991.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 598px;
    }
  }
}

@media (max-width: 767.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 406px;
    }
  }
}

@media (max-width: 575.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 352px;
    }
  }
}
</style>
