import Vue from 'vue'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    uploadPercentage: 0,
  },
  getters: {
    /* getUploadPercentage(state) {
      return state.uploadPercentage
    }, */
  },
  mutations: {
    mutateUploadPercentage(state, value) {
      state.uploadPercentage = value
    },
  },
  actions: {
    fetchForms(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/fetchForms', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteForms(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/deleteForms', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFormUpdate(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/fetchFormUpdate', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    requestFormUpdate(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        if (Array.isArray(val)) {
          if (val[0] === Object(val[0]) && (val[0] instanceof File) === false) {
            formData.append(key, JSON.stringify(val))
          } else {
            val.forEach(value => formData.append(`${key}[]`, value))
          }
        } else {
          formData.append(key, val)
        }
      })

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/requestFormUpdate', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    requestFormUpdate2(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/requestFormUpdate', form, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    denyFormUpdate(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        if (Array.isArray(val)) {
          if (val[0] === Object(val[0]) && (val[0] instanceof File) === false) {
            formData.append(key, JSON.stringify(val))
          } else {
            val.forEach(value => formData.append(`${key}[]`, value))
          }
        } else {
          formData.append(key, val)
        }
      })

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/denyFormUpdate', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    approveFormUpdate(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        if (Array.isArray(val)) {
          if (val[0] === Object(val[0]) && (val[0] instanceof File) === false) {
            formData.append(key, JSON.stringify(val))
          } else {
            val.forEach(value => formData.append(`${key}[]`, value))
          }
        } else {
          formData.append(key, val)
        }
      })

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/approveFormUpdate', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    dismissFormUpdate(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        if (Array.isArray(val)) {
          if (val[0] === Object(val[0]) && (val[0] instanceof File) === false) {
            formData.append(key, JSON.stringify(val))
          } else {
            val.forEach(value => formData.append(`${key}[]`, value))
          }
        } else {
          formData.append(key, val)
        }
      })

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/dismissFormUpdate', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchForm(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/fetchForm', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteFormFile(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/deleteFormFile', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkEmailAddress(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/checkEmailAddress', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addForm(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        if (Array.isArray(val)) {
          if (val[0] === Object(val[0]) && (val[0] instanceof File) === false) {
            formData.append(key, JSON.stringify(val))
          } else {
            val.forEach(value => formData.append(`${key}[]`, value))
          }
        } else {
          formData.append(key, val)
        }
      })

      // console.log([...formData])

      const config = {
        onUploadProgress: progressEvent => {
          const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100), 10)
          ctx.commit('mutateUploadPercentage', uploadPercentage)

          // console.log(uploadPercentage)
        },
      }

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/addForm', formData, config, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateForm(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        if (Array.isArray(val)) {
          if (val[0] === Object(val[0]) && (val[0] instanceof File) === false) {
            formData.append(key, JSON.stringify(val))
          } else {
            val.forEach(value => formData.append(`${key}[]`, value))
          }
        } else {
          formData.append(key, val)
        }
      })

      // console.log([...formData])

      const config = {
        onUploadProgress: progressEvent => {
          const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100), 10)
          ctx.commit('mutateUploadPercentage', uploadPercentage)

          // console.log(uploadPercentage)
        },
      }

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/updateForm', formData, config, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchForm1(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/fetchForm1', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addForm1(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        if (Array.isArray(val)) {
          if (val[0] === Object(val[0]) && (val[0] instanceof File) === false) {
            formData.append(key, JSON.stringify(val))
          } else {
            val.forEach(value => formData.append(`${key}[]`, value))
          }
        } else {
          formData.append(key, val)
        }
      })

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/addForm1', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateForm1(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        if (Array.isArray(val)) {
          if (val[0] === Object(val[0]) && (val[0] instanceof File) === false) {
            formData.append(key, JSON.stringify(val))
          } else {
            val.forEach(value => formData.append(`${key}[]`, value))
          }
        } else {
          formData.append(key, val)
        }
      })

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/updateForm1', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchForm2(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/fetchForm2', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addForm2(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        if (Array.isArray(val)) {
          if (val[0] === Object(val[0]) && (val[0] instanceof File) === false) {
            formData.append(key, JSON.stringify(val))
          } else {
            val.forEach(value => formData.append(`${key}[]`, value))
          }
        } else {
          formData.append(key, val)
        }
      })

      const config = {
        onUploadProgress: progressEvent => {
          const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100), 10)
          ctx.commit('mutateUploadPercentage', uploadPercentage)
        },
      }

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/addForm2', formData, config, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateForm2(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        if (Array.isArray(val)) {
          if (val[0] === Object(val[0]) && (val[0] instanceof File) === false) {
            formData.append(key, JSON.stringify(val))
          } else {
            val.forEach(value => formData.append(`${key}[]`, value))
          }
        } else {
          formData.append(key, val)
        }
      })

      const config = {
        onUploadProgress: progressEvent => {
          const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100), 10)
          ctx.commit('mutateUploadPercentage', uploadPercentage)
        },
      }

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/updateForm2', formData, config, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteForm2File(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/deleteForm2File', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchForm3(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/fetchForm3', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addForm3(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        if (Array.isArray(val)) {
          if (val[0] === Object(val[0]) && (val[0] instanceof File) === false) {
            formData.append(key, JSON.stringify(val))
          } else {
            val.forEach(value => formData.append(`${key}[]`, value))
          }
        } else {
          formData.append(key, val)
        }
      })

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/addForm3', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateForm3(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        if (Array.isArray(val)) {
          if (val[0] === Object(val[0]) && (val[0] instanceof File) === false) {
            formData.append(key, JSON.stringify(val))
          } else {
            val.forEach(value => formData.append(`${key}[]`, value))
          }
        } else {
          formData.append(key, val)
        }
      })

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/updateForm3', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchForm4(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/fetchForm4', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addForm4(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        if (Array.isArray(val)) {
          if (val[0] === Object(val[0]) && (val[0] instanceof File) === false) {
            formData.append(key, JSON.stringify(val))
          } else {
            val.forEach(value => formData.append(`${key}[]`, value))
          }
        } else {
          formData.append(key, val)
        }
      })

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/addForm4', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateForm4(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        if (Array.isArray(val)) {
          if (val[0] === Object(val[0]) && (val[0] instanceof File) === false) {
            formData.append(key, JSON.stringify(val))
          } else {
            val.forEach(value => formData.append(`${key}[]`, value))
          }
        } else {
          formData.append(key, val)
        }
      })

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/updateForm4', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteForm5File(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/deleteForm5File', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchForm5(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/fetchForm5', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addForm5(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        if (Array.isArray(val)) {
          if (val[0] === Object(val[0]) && (val[0] instanceof File) === false) {
            formData.append(key, JSON.stringify(val))
          } else {
            val.forEach(value => formData.append(`${key}[]`, value))
          }
        } else {
          formData.append(key, val)
        }
      })

      return new Promise((resolve, reject) => {
        axios
        // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/addForm5', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateForm5(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        if (Array.isArray(val)) {
          if (val[0] === Object(val[0]) && (val[0] instanceof File) === false) {
            formData.append(key, JSON.stringify(val))
          } else {
            val.forEach(value => formData.append(`${key}[]`, value))
          }
        } else {
          formData.append(key, val)
        }
      })

      return new Promise((resolve, reject) => {
        axios
        // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/updateForm5', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchForm6(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/fetchForm6', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addForm6(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        formData.append(key, val)
      })

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/addForm6', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateForm6(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        formData.append(key, val)
      })

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/updateForm6', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchForm7(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/fetchForm7', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addForm7(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        formData.append(key, val)
      })

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/addForm7', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateForm7(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        formData.append(key, val)
      })

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/updateForm7', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchForm8(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/fetchForm8', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addForm8(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        formData.append(key, val)
      })

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/addForm8', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateForm8(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        formData.append(key, val)
      })

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/updateForm8', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchForm9(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/fetchForm9', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addForm9(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        formData.append(key, val)
      })

      const config = {
        onUploadProgress: progressEvent => {
          const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100), 10)
          ctx.commit('mutateUploadPercentage', uploadPercentage)
        },
      }

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/addForm9', formData, config, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateForm9(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        formData.append(key, val)
      })

      const config = {
        onUploadProgress: progressEvent => {
          const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100), 10)
          ctx.commit('mutateUploadPercentage', uploadPercentage)
        },
      }

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/updateForm9', formData, config, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchForm10(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/fetchForm10', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addForm10(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        formData.append(key, val)
      })

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/addForm10', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateForm10(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        formData.append(key, val)
      })

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/updateForm10', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchForm11(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/fetchForm11', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addForm11(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        formData.append(key, val)
      })

      // console.log([...formData])

      const config = {
        onUploadProgress: progressEvent => {
          const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100), 10)
          ctx.commit('mutateUploadPercentage', uploadPercentage)

          // console.log(uploadPercentage)
        },
      }

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/addForm11', formData, config, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateForm11(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        formData.append(key, val)
      })

      // console.log([...formData])

      const config = {
        onUploadProgress: progressEvent => {
          const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100), 10)
          ctx.commit('mutateUploadPercentage', uploadPercentage)

          // console.log(uploadPercentage)
        },
      }

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/updateForm11', formData, config, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchForm12(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/fetchForm12', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addForm12(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        if (Array.isArray(val)) {
          if (val[0] === Object(val[0]) && (val[0] instanceof File) === false) {
            formData.append(key, JSON.stringify(val))
          } else {
            val.forEach(value => formData.append(`${key}[]`, value))
          }
        } else {
          formData.append(key, val)
        }
      })

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/addForm12', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateForm12(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        if (Array.isArray(val)) {
          if (val[0] === Object(val[0]) && (val[0] instanceof File) === false) {
            formData.append(key, JSON.stringify(val))
          } else {
            val.forEach(value => formData.append(`${key}[]`, value))
          }
        } else {
          formData.append(key, val)
        }
      })

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/updateForm12', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteForm12File(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/deleteForm12File', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchForm13(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/fetchForm13', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addForm13(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/addForm13', form, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateForm13(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/updateForm13', form, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchForm14a(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/fetchForm14a', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addForm14a(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        if (Array.isArray(val)) {
          if (val[0] === Object(val[0]) && (val[0] instanceof File) === false) {
            formData.append(key, JSON.stringify(val))
          } else {
            val.forEach(value => formData.append(`${key}[]`, value))
          }
        } else {
          formData.append(key, val)
        }
      })

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/addForm14a', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateForm14a(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        if (Array.isArray(val)) {
          if (val[0] === Object(val[0]) && (val[0] instanceof File) === false) {
            formData.append(key, JSON.stringify(val))
          } else {
            val.forEach(value => formData.append(`${key}[]`, value))
          }
        } else {
          formData.append(key, val)
        }
      })

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/updateForm14a', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteForm14aFile(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/deleteForm14aFile', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchForm14b(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/fetchForm14b', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addForm14b(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/addForm14b', form, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateForm14b(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/updateForm14b', form, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchForm14c(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/fetchForm14c', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addForm14c(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        if (Array.isArray(val)) {
          if (val[0] === Object(val[0]) && (val[0] instanceof File) === false) {
            formData.append(key, JSON.stringify(val))
          } else {
            val.forEach(value => formData.append(`${key}[]`, value))
          }
        } else {
          formData.append(key, val)
        }
      })

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/addForm14c', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateForm14c(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        if (Array.isArray(val)) {
          if (val[0] === Object(val[0]) && (val[0] instanceof File) === false) {
            formData.append(key, JSON.stringify(val))
          } else {
            val.forEach(value => formData.append(`${key}[]`, value))
          }
        } else {
          formData.append(key, val)
        }
      })

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/updateForm14c', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadFiles15(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/uploadFiles15', form, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchForm15(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/fetchForm15', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addForm15(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/addForm15', form, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateForm15(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/updateForm15', form, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchForm16(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/fetchForm16', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addForm16(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        formData.append(key, val)
      })

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/addForm16', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateForm16(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        formData.append(key, val)
      })

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/updateForm16', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addForm17a(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/addForm17a', form, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateForm17a(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/updateForm17a', form, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchForm17a(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/fetchForm17a', form, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addForm17b(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/addForm17b', form, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateForm17b(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/updateForm17b', form, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchForm17b(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/fetchForm17b', form, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchForm19(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/fetchForm19', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addForm19(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/addForm19', form, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateForm19(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/updateForm19', form, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchForm21(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/fetchForm21', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addForm21(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        formData.append(key, val)
      })

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/addForm21', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateForm21(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        formData.append(key, val)
      })

      // console.log([...formData])

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/updateForm21', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchForm22(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/fetchForm22', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addForm22(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/addForm22', form, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateForm22(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/updateForm22', form, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCaregiverData(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'hr/fetchCaregiverData', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    /* Genetate link for the preview function */
    preview: () => {
      /* Get params in URL */
      const param = window.location.href.split('/')
      const form = param[5].split('form')[1] /* form name */
      const id = param[6].split('=')[1] /* user id */

      const userData = JSON.parse(localStorage.userData)
      const token = {
        accessToken: localStorage.accessToken, refreshToken: localStorage.refreshToken, role: userData.role, form, id,
      }

      const encryptedToken = btoa(JSON.stringify(token)) /* encrypt with base64 */
      console.log('token', token)

      // eslint-disable-next-line
      const link = Vue.prototype.$urlForms + `?q=${encryptedToken}`

      window.open(link) /* Open new link */
    },
  },
}
